export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
export const CLEAR_LOADING = 'CLEAR_LOADING'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const CURRENT_SIDEBAR = 'CURRENT_SIDEBAR'
export const SET_REGION = 'SET_REGION'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_ALLFRAME = 'SET_ALLFRAME'
export const SET_MAINAGENT = 'SET_MAINAGENT'
export const SET_BONUSGENERATE = 'SET_BONUSGENERATE'
export const SET_PRODUCTFRAME = 'SET_PRODUCTFRAME'
export const SET_BONUSGROUP = 'SET_BONUSGROUP'
export const SET_TRIPPRODUCTBONUS = 'SET_TRIPPRODUCTBONUS'
export const SET_QUESTIONTYPE = 'SET_QUESTIONTYPE'
export const SET_BONUSAGENT = 'SET_BONUSAGENT'
export const SET_ALLBONUS = 'SET_ALLBONUS'
export const SET_SIXMONTHTRIP = 'SET_SIXMONTHTRIP'
export const ADD_NOTI_EXCLUDE_AGENT = 'ADD_NOTI_EXCLUDE_AGENT'
export const REMOVE_NOTI_EXCLUDE_AGENT = 'REMOVE_NOTI_EXCLUDE_AGENT'
export const ADD_NOTI_EXCLUDE_ALL_AGENTS = 'ADD_NOTI_EXCLUDE_ALL_AGENTS'
export const REMOVE_NOTI_EXCLUDE_ALL_AGENTS = 'REMOVE_NOTI_EXCLUDE_ALL_AGENTS'
export const INFINITE_SCROLL_COUNT = 'INFINITE_SCROLL_COUNT'
export const SET_PRODUCTDETAIL = 'SET_PRODUCTDETAIL'
export const SET_PRODUCTGROUPDETAIL = 'SET_PRODUCTGROUPDETAIL'
export const SET_PRODUCTGROUPS = 'SET_PRODUCTGROUPS'
export const SET_REPORTREGIONS = 'SET_REPORTREGIONS'
export const TOGGLE_BUTTONDISABLED = 'TOGGLE_BUTTONDISABLED'
export const SET_AGENTLEVEL = 'SET_AGENTLEVEL'
export const SET_BONUSTYPE = 'SET_BONUSTYPE'
export const SET_YEARENDTRIPGROUP = 'SET_YEARENDTRIPGROUP'
export const SET_YEARENDAWARDGROUP = 'SET_YEARENDAWARDGROUP'
export const SET_AllBIRTHDAY = 'SET_AllBIRTHDAY'
export const SET_EVENT = 'SET_EVENT'
